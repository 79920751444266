import * as React from "react"
import Helmet from "react-helmet";
import Layout from '../../components/Layout'
import Box from "@mui/material/Box";
import ProjectHeader from "../../components/Common/ProjectHeader";
import {browserTitle, seoDescription, seoTitle, keywords} from "../../data/products/robot";
import {BackBox, Box4Title, ProductContact, SubTitleEnBox, SubTitleWhiteBox} from "../../components/Common/Base";
import Grid from "@mui/material/Grid/Grid";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";


const City3d = (props) => {
  const { location } = props;

  const eleShow = React.useRef();

  const handleNextClick = () => {
    eleShow.current && eleShow.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  };

  return (
    <Layout location={location}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={keywords} />
        <title>{browserTitle}</title>
      </Helmet>
      <ProjectHeader
        backImg={require('../../images/products/robot/01.jpg').default}
        backVideo={'products/robot/01.m3u8'}
        subTitle={'微信机器人'}
        title={'将紧急信息第一时间主动推送至微信群中'}
        titleEn={'WeChat Bot'}
        titleEn2={'Push The Emergency Information to The WeChat Group at The First Time'}
        // gridShow
        handleNextClick={handleNextClick}
      />
      {/* box2 */}
      <BackBox
        ref={eleShow}
        sx={{
          backgroundImage: `url(${require('../../images/products/robot/02.jpg').default})`,
          minHeight: '25.9vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 4
        }}
      >
        <SubTitleWhiteBox
          variant="h5"
          gutterBottom
          sx={{
            fontSize: {
              sm: '2.5vw',
              xs: '18px',
              lg: '48px'
            },
          }}
        >
          实现与数据逻辑的智能对话
        </SubTitleWhiteBox>
        <SubTitleEnBox
          gutterBottom
          variant="h6"
          sx={{
            fontSize: {
              xs: '2vw',
              lg: '32px'
            },
            color: '#fff',
          }}
        >
          Enabling intelligent dialogue with data logic
        </SubTitleEnBox>
      </BackBox>
      {/* box3 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 6,
            sm: 12,
            md: 18,
            lg: 24
          },
          pb: {
            xs: 6,
            sm: 12,
            md: 18,
            lg: 24
          }
        }}
      >
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <Stack
              direction={{
                xs: 'column',
              }}
              spacing={0}
            >
              <Box4Title
                variant="h4"
                gutterBottom
                sx={{
                  textAlign: {
                    xs: 'center',
                  }
                }}
              >
                微信机器人
              </Box4Title>
              <Box4Title
                variant="h4"
                gutterBottom
                sx={{
                  textAlign: {
                    xs: 'center',
                  }
                }}
              >
                WeChat Bot
              </Box4Title>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
            >
              为了给专业用户及微信群提供高效率、便捷的服务，针对气象数据、气象业务咨询建设智慧气象服务AI助手，
              通过自然语言处理、语义分析等人工智能技术，在微信中实现智能聊天对话及群信息管理功能
            </SubTitleWhiteBox>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                // color: '#fff',
              }}
            >
              In order to provide efficient and convenient services to professional users and
              WeChat groups, we build a smart weather service AI assistant for weather data
              and weather business consulting, and realize intelligent chatting conversation
              and group information management functions in WeChat through natural language processing,
              semantic analysis and other artificial intelligence technologies
            </SubTitleEnBox>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <img width="100%" alt="" src={require('../../images/products/robot/03.png').default} />
          </Grid>
        </Grid>
      </Container>
      {/* box3 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Grid
          container
          spacing={4}
          direction="row-reverse"
          alignItems="stretch"
        >
          <Grid
            item
            xs={12}
            md={4}
          >
            <Stack
              direction={{
                xs: 'column',
              }}
              spacing={0}
            >
              <Box4Title
                variant="h4"
                gutterBottom
                sx={{
                  textAlign: {
                    xs: 'center',
                  }
                }}
              >
                产品功能
              </Box4Title>
              <Box4Title
                variant="h4"
                gutterBottom
                sx={{
                  textAlign: {
                    xs: 'center',
                  }
                }}
              >
                Product Features
              </Box4Title>
            </Stack>
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
            >
              提供详细天气预报和气象信息快报，让用户可以提前计划和安排。其中包括以下主要功能：
              <br />
              <br />
              天气信息快报摘要服务<br />
              天气预警信息服务<br />
              天气提示服务<br />
            </SubTitleWhiteBox>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                // color: '#fff',
              }}
            >
              rovides detailed weather forecasts and weather information snapshots, allowing users to plan and schedule in advance. It includes the following main functions.
              <br />
              <br />
              Weather information snapshot summary service<br />
              Weather alert information service<br />
              Weather alert service<br />
            </SubTitleEnBox>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            container
            alignItems="stretch"
            spacing={4}
          >
            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <img alt="" width="80%" src={require('../../images/products/robot/141.png').default} />
              <Box
                sx={{
                  pt: 4,
                  display: {
                    xs: 'none',
                    md: 'block',
                  }
                }}
              >
                <SubTitleWhiteBox
                  variant="h5"
                  gutterBottom
                >
                  智能处理引擎用于实施用户与机器人的问答交互，主要实现包括语义分析、对话管理、答案渲染等内容，并具有自学习过程以提升用户意图理解能力
                </SubTitleWhiteBox>
                <SubTitleEnBox
                  gutterBottom
                  variant="h6"
                  sx={{
                    // color: '#fff',
                  }}
                >
                  Intelligent processing engine for implementing user-robot Q&A interactions,
                  mainly including semantic analysis, dialogue management, answer rendering, etc.,
                  with a self-learning process to improve user intent understanding
                </SubTitleEnBox>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Box sx={{flex: 1}} />
              <img alt="" width="80%" src={require('../../images/products/robot/142.jpg').default} />
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            pt: 4,
            display: {
              xs: 'block',
              md: 'none',
            }
          }}
        >
          <SubTitleWhiteBox
            variant="h5"
            gutterBottom
          >
            智能处理引擎用于实施用户与机器人的问答交互，主要实现包括语义分析、对话管理、答案渲染等内容，并具有自学习过程以提升用户意图理解能力
          </SubTitleWhiteBox>
          <SubTitleEnBox
            gutterBottom
            variant="h6"
            sx={{
              // color: '#fff',
            }}
          >
            Intelligent processing engine for implementing user-robot Q&A interactions,
            mainly including semantic analysis, dialogue management, answer rendering, etc.,
            with a self-learning process to improve user intent understanding
          </SubTitleEnBox>
        </Box>
      </Container>
      {/* box4 */}
      <ProductContact />
    </Layout>
  )
}


export default City3d;
